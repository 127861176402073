/*
* Mixin helpers for slide transitions
*/
@mixin carouselTransition($transition: all, $duration: 300ms, $easing: ease-in) {
    transition: $transition $duration $easing;
}

@mixin carouselTransitionDelay($delay:0) {
    transition-delay: $delay;
}

@mixin carouselBackfaceVisibility($visibility: hidden) {
    backface-visibility: $visibility;
}

@mixin carouselPerspective($perspective: 1000) {
    perspective: $perspective;
}

@mixin carouselTranslate3D ($x: 0, $y:0, $z:0) {
    transform: translate3d($x, $y, $z);
}

@mixin carouselTranslateZ () {
    transform: translateZ(0);
}

@mixin carouselTranslateY ($y:0) {
    transform: translateY($y);
}

/*
* Set default desktop and mobile
* heights on carousel wrapper
*/
.carousel {
    @include carouselBackfaceVisibility();
    @include carouselPerspective();
    @include carouselTransition(opacity, 700ms, ease-out);
    position: relative;
    background: $white;
    overflow: hidden;
    opacity: 0;
    width: 100%;
    height: 420px;

    @media screen and (min-width: 768px) {
        height: 600px;    
    }

    .ctaBtn {
        position: relative;
        opacity: 0;
    }    

    &.showCarousel {
        opacity: 1;
        transition: opacity 1000ms ease-out;

        .ctaBtn {
            &.posTop {
                opacity: 1;
            }
        }

    }
}

/*
* Default styles for carousel slides
*/
.carousel-slide {
    @include carouselBackfaceVisibility();
    @include carouselPerspective();
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: #000;

    /*
    * Default styles for slide content
    */
    .slideTextWrap {
        display: table-cell;
        width: 100%;
        opacity: 0;
        vertical-align: middle;
        text-align: center;
    }

    &.nextSlide, &.prevSlide {
        opacity: 0;
        transition: opacity 700ms ease-out;
    }

    &.currentSlide {
        opacity: 1;
        transition: opacity 700ms ease-out;
    }
}

/* 
* Default styles for carousel arrows
*/
.carouselArrows {
    @include carouselBackfaceVisibility();
    @include carouselPerspective();
    display: block;
    position: absolute;
    z-index: 5;
    margin-top: -25px;
    text-indent: -9999px;
    width: 24px;
    height: 53px;
    border: 0;
    top: 50%;
    left: 10px;

    @media screen and (min-width: 768px) {
        left: 25px;
    }

    @media #{$xsmall} {
        display: none;
    }

    &.nextArrow {
        left: auto;
        right: 10px;
        background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAA1CAMAAABsm9siAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABL1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAACrb73kAAAAY3RSTlMAC6tOt/EtQvnfFWXFBo+iA7V4D9L8JOr0P+NiyIukAbL+UCH1LzsYXwqIsXwMUx4zOBxbh6d/0VfoNsfpNVUCfqZcORofMQ1SeolgCDwXIi6zjKNjQOHy07Z2kKBmw9248ExL8HRhAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAARtJREFUOMt9lNVWQzEQRdtiF79QoHgpFClOcS3u7m7n//+B0/cd8nj2mpU9SSaJZKoigatSVdUIaiLV1iGplxoakTRJzQjiFqkVSbpN7azWkVEnq3VF6ma1HqmX1fqk/iyqDfyjlhtEMpRRfhjJiNVGkYxZrYBkPKg2IU1iyZTVppHMWG0WyZzVikjmg2oL0iKqZa22hCWFZeVWkBRXlV9D4he1jhUbym/SHm5/C/KSrbYDfexQH7uBzvd8VvuQH/h0DyE/stAx5OUbPCGh08Cdn1noHPILC11CfmWha8jLb5fGKr4JvPZb6Y7ye18NTdRDpEeaQU/tE01t+pnnPH6RXmnjN+md8g/pk4T8X33hp/CtH/5GkqlfzP8AHZA3tJlL6y4AAAAASUVORK5CYII=');
        
        @media screen and (min-width: 768px) {
            right: 25px;
        }

    }

    &.prevArrow {
        background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAA1CAMAAABsm9siAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA+VBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAABBIk2zAAAAUXRSTlMATKsLLfC6Fd35QgbCaJ+RdrgDTvzVD/HqJOBBxmSijni0/dL1I+M+B8hhAaSKebFQ/g4wIBk6Cl2HfAJUNBzHZTfnV89/r6hbM1MYL/Shxd/NeXzSAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAQ1JREFUOMt9lNdawlAQBldQMcaCDbGCgDW22LCi2Lv+7/8yrnI7ay5nv3My244ZfX2FIvL+AQ0SLw0pGQaejkijdGBMGideliYmgU9Na6ZCQrOqzpHQvJIFElqUlujHy1KNeF1aQaGGmijUUnWVhNaUrAPf2JS2gGfb0g4J7Up7JLSfq3kA/NCFjoAfu9AJ8LYLnQZCZyR07kIXwC9diKboyoU6dNG1dEPcui3d4gm7y3XfxsiD9JhRIHsK8vjNPH/GSMdr1cXIS5SMvQbp9wpGHYxLHDbF7C1oo9l70PjeqFQwUv9PDcfR0mCA45H3Jfngrf3b81gNF9HsM1hdS7+iiSp98/MQv3CuVij+ABKeMIt2aETqAAAAAElFTkSuQmCC');
    }

}

/*
* Default styles for carousel dot pagination
*/
.goto-dots {
    @include carouselBackfaceVisibility();
    @include carouselPerspective();
    position: absolute;
    bottom: 25px;
    left: 0;
    z-index: 8;
    width: 100%;
    text-align: center;

    ul {
        display: inline-block;
        margin:0;
        padding: 0;

        li {
            display: inline-block;
        }

        a {
            display: block;
            margin: 0 5px;
            width: 16px;
            height: 16px;
            line-height: 0;
            text-indent: -9999px;

            background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAe1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAADNPGDiAAAAJ3RSTlMAKo7X+NYGlP2YCbv5kTgMvZXlMTDkK/wvLo+SNwUL89SQLC3Y+vTadafJAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAIxJREFUGNNlj9kSgjAMRQOtgNqCKLSyWGld7v//oQW0dfQ8JWeSzA2RJ0kZ55s0oZUsL7BQbHdLv4eQZVWVUuCQeVHjeFpHmxY5kdL8/N6ljmtFPQYKDOiJYYxiBCOBSxQGwgvzLa6/K5M/KqOQsOQ07z79jWs3B2ubEKwO0Y0J0f+e8yh7fzwn6+b6Bbl9C9nlg4QdAAAAAElFTkSuQmCC');
            background-repeat: no-repeat;
            background-position: 0 0;

            @include carouselTransition(background-image, 150ms);
        }

        a:hover {
            background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////8AAAAIicFMAAAAGXRSTlMAKo7X+NYGlP2YCbu9lSv8j/nz1JAs2Pr0wD1WjwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABgSURBVBjTbY9ZDoAgDAUfi4ILm4DK/S8qYmIAna92kravQIZQxvlACR5GIVNBTnPpl/SyjlmoVCEAbWphNGxqsGCtYHCtcL3Y+hHfLw2I7dnYBVN/0T/PZXTYj9OHeNcXpPASud8uSJoAAAAASUVORK5CYII=');
        }

        .active {
            background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////8AAAAIicFMAAAAGXRSTlMAKo7X+NYGlP2YCbu9lSv8j/nz1JAs2Pr0wD1WjwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABgSURBVBjTbY9ZDoAgDAUfi4ILm4DK/S8qYmIAna92kravQIZQxvlACR5GIVNBTnPpl/SyjlmoVCEAbWphNGxqsGCtYHCtcL3Y+hHfLw2I7dnYBVN/0T/PZXTYj9OHeNcXpPASud8uSJoAAAAASUVORK5CYII=');
        }
    }
}

/*
* Styles if CSS transitions are supported
*/  
.csstransforms3d {
    /*
    * Slide position when next is triggered
    */
    .slideLeft {
        /* Stupid Chrome Bug */
        animation-name: none;        
        .carousel-slide {
            @include carouselTranslate3D(100%,0,0);
        }
    }
    /*
    * Slide position when previous is triggered
    */    
    .slideRight {
        /* Stupid Chrome Bug */
        animation-name: none;        
        .carousel-slide {
            @include carouselTranslate3D(-100%,0,0);
        }
    }
    /*
    * Keep new slide on top
    */     
    .onTop {
        z-index: 5;
    }

    .slideTextWrap {
        @include carouselTranslateY(40px);
    }

    .carousel {
        .ctaBtn {
            @include carouselTranslateY(40px);
            margin-top: 40px;
        }
    }

    .showCarousel {
        .slideTextWrap{
            &.posTop {
                @include carouselBackfaceVisibility();
                @include carouselPerspective();
                @include carouselTransition(all, 700ms, ease-out);
                @include carouselTranslateY(0);
                opacity: 1;
            }
        }

        .ctaBtn {
            &.posTop {
                @include carouselBackfaceVisibility();
                @include carouselPerspective();
                @include carouselTransition(all, 700ms, ease-out);
                @include carouselTransitionDelay(300ms);
                @include carouselTranslateY(0);
            }
        }
    }
}

/*
* Fallback if CSS transitions are not support
*/  
.no-csstransforms3d {
    /*
    * Slide position when next is triggered
    */
    .slideLeft {
        .carousel-slide {
            left: 100%;
        }
    }
    /*
    * Slide position when previous is triggered
    */  
    .slideRight {
        .carousel-slide {
            left: -100%;
        }
    }
    /*
    * Keep new slide on top
    */   
    .onTop {
        z-index: 5;
    }

    .showCarousel {
        .slideTextWrap{
            &.posTop {
                opacity: 1;
            }
        }
    }
}