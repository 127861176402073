.section-intro {
	width: 100%;
	display: block;
	margin: 0;
	padding: 0;

	background-image: url('../images/bg-intro.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;

	.intro-wrapper {
		max-width: 1600px;
		padding: 5% 7% 190px;
		position: relative;

		@media #{$xsmall} {
			padding: 20px 20px 40px 20px;
		}
	}

	.social-links {
		margin: -7px 0 0; 
		padding: 0;
		float: right;
		
		@media #{$xsmall} {
			top: 20px;
			right: 0px;
			margin: 0;
		}

		li {
			margin: 0 3px 0 0; 
			padding: 0;
			display: inline-block;
			list-style: none;
			color: white;
			a {
				text-indent: -999em;
			}
			
			@media #{$xsmall} {
				margin: 0;
				float: right;

				.icon-facebook, .icon-twitter {
					display: none;
				}
				.icon-finnish {
					width: 20px;
					height: 20px;
					background-size: 20px 20px;
				}
			}
		}
	}	

	.intro-heading {
		width: 100%;
		background-image: url('../images/bag.png');
		background-repeat: no-repeat;
		background-position: 100% 100%;
		background-size: 48%;

		@media #{$xsmall} { 
			background-image: none;
		}

		h1 {
			color: white;
			font-size: 9vw;
			line-height: 7vw;
			font-family: $boldFontFamily;
			text-transform: uppercase;
			font-weight: normal;
			width: 65%;

			@media #{$xsmall} {
				width: 100%;
				font-size: 15vw;
				line-height: 13vw;
			}
		}
	}

	.gainomax-logo {
		margin-top: 10px;
		float: left;
		background-image: none;

		img {
			width: 26vw;
			display: block;
		}

		@media #{$xsmall} {
			float: right;
			background-size: 125px;
			// width: 125px;
			margin-top: -13%;

			img {
				width: 41vw;
				margin: 0;
			}
		}
	}

	.gainomax-bag {
		display: none;
		@media #{$xsmall} { 
			display: block;
			margin: 0;
			padding: 0;
			text-align: center;
			img {
				width: 90%;
				text-align: center;
				margin: 0 auto;
			}
		}
	}

	.watch-the-film {
		float: right;
		position: relative;
		margin-right: 10vw;
		margin-top: 3%;
		
		@media #{$xsmall} {
			float: none;
			margin: 3% auto 0;
			text-align: center;
		}

		a {
			text-decoration: none;
			color: $white;
			font-size: 3vw;
			line-height: 50px;
			min-height: 50px;
			padding-right: 65px;
			padding-top: 15px;
			padding-bottom: 15px;			

			background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAMAAAAPkIrYAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABC1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8/PyioqLT09M2NjQAAAB4eHfl5eVZWVcaGhh3d3bi4uJXV1UfHx0ODgwXFxbh4eEdHRseHhwNDQsYGBZ6ennj4+Pm5uZ2dnXi4uF5eXjk5OMZGRd4eHjk5OR4eHb7+/shIR8eHh3Z2dhrmJEKAAAANXRSTlMAMVeLp8TZ6PDxGWCj4FGs9Qh0bN9DzAOSL9T8d4dUJ92WONtkBOxt5Uu0El8BLFyIq8Xp89lihHYAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAACAklEQVRYw63YezsCQRQG8Om2FtFN6ELKpU1CcciEiCKE3L//J7Hb5kHtZaZ933/n2d/T7kwzZw5jTvH5A8GQMqVOq1NKKBjw+9hkmZkNz9Fo5sKzM9LSfCRK1olG5mWgWFwhpyjxmCiVWCC3LCSEpOSiq2RkMekqLS0LSUaWl5ypVFqYIkqnnCh/RoIiyvjtqayUZCRrR61IU0QrOMoGi09EEcXHqdUJKaLVUSo3MUWUG7HWPFhr/6m8B4oo/5cqeKKICn+sdY/W+i+14ZEi2vihNrc8W1ubQ6vomSIqDi3NavDouC5jaSZVsh494Y1TCaw0sLatB884P29eCFvbBlW22f8ueeuKXzfbglamrFs7ZGd1blq3vHt3L4bt6FbQ3uro2hXvNh5ErCBjFc3J0qNr571Hd0ursF1ysQZvev3kPgu7bM/VGmqNZxdrj+0LWD/fzXm97bOqkGVq/V7bwaqymqA1fNM7++9WY4qwZWrdl1ebBxR2IGGZK6T/Zj0LB0yVs8xZeKq/jz+gskNJS9c+OP/8Gn/gEPq7kN8LOY/I9YVc98j/I3KfQO5fyH0Vud9DzyHk+Yg8t6H1BLLOgdZfyLoQWa9C62hofY+8d0DvQ9B7GvL+CL3XQu/b0D4AtD8B7ZtA+znQPhND9r+M4PpyDNovNALrYw6C66+akev7fgMrIa0leEkFRAAAAABJRU5ErkJggg==');
			background-repeat: no-repeat;
			background-position: 100% 50%;
			background-size: 50px;

			@media #{$xsmall} {
				font-size: 8vw;
				text-align: center;
				padding-right: 55px;
				background-size: 40px;
			}
		}
	}
}