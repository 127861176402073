.section-competition {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 70vh;
	background-image: url('../images/bg-competition.jpg');
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	text-align: center;
	position: relative;

	@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) { 
		min-height: 300px;
	}

	// ipad landscape and portrait
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
		height: 500px;
	}

	.competition-wrapper {
		padding: 120px 30px;	
	}

	.products-offers-img {
		background-image: url('../images/products-offers.png');
		background-repeat: no-repeat;
		background-size: 90%;
		background-position: 50% 50%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		display: block;

		@media #{$xsmall-min} {
			background-size: 85%;
		}

		@media #{$large} {
			background-size: 45%;
		}

		@media #{$xlarge} {
			background-size: 35%;
		}
		
		// iphone landscape
		@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) { 
			background-size: 50%;
		}

		// ipad landscape
		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) { 
			background-size: 45%;
		}
	}

	.products-offers-content {
		background-image: url('../images/products-offers-en.png');
		background-repeat: no-repeat;
		background-size: 40%;
		background-position: 70% 50%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		display: block;

		@media #{$xsmall-min} {
			background-size: 28%;
			background-position: 80% 50%;
		}

		@media #{$large} {
			background-size: 15%;
			background-position: 70% 50%;
		}

		@media #{$xlarge} {
			background-size: 10%;
			background-position: 65% 50%;
		}

		// iphone landscape
		@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) { 
			background-size: 20%;
		}

		// ipad landscape
		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) { 
			background-size: 15%;
			background-position: 70% 50%;
		}
	}
}

.fi { 
	.products-offers-img {
		background-size: 65%;
		background-position: 50% 30%;

		@media #{$xsmall-min} {
			background-size: 65%;
		}

		@media #{$large} {
			background-size: 35%;
		}

		@media #{$xlarge} {
			background-size: 30%;
		}
		
		// iphone landscape
		@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) { 
			background-size: 40%;
			background-position: 50% 20%;
		}

		// ipad landscape
		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) { 
			background-size: 35%;
		}

	}
	.products-offers-content {
		background-image: url('../images/products-offers-fi.png');
		background-size: 30%;
		background-position: 75% 33%;

		@media #{$xsmall-min} {
			background-size: 20%;
			background-position: 75% 40%;
		}

		@media #{$large} {
			background-size: 12%;
			background-position: 65% 40%;
		}

		@media #{$xlarge} {
			background-size: 10%;
			background-position: 62% 40%;
		}

		// iphone landscape
		@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) { 
			background-size: 20%;
		}

		// ipad landscape
		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) { 
			background-size: 12%;
			background-position: 65% 40%;
		}
	}
	.products-offers-wrapper {
		margin: 0 auto;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 90%;

		@media #{$xsmall-min} {
			max-width: 500px;
			bottom: 100px;
			width: 100%;
		}		

		@media #{$large} {
			max-width: 500px;
			bottom: 30px;
			width: 100%;
		}		

		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) { 
			bottom: 70px;
		}
	}
}

