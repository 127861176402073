.section-products {
	width: 100%;
	display: block;
	background: $darkGrey;	
	margin: 0;
	padding: 0;

	.products-wrapper {
		padding: 50px 30px 60px;

		@media #{$xsmall} {
			padding: 50px 20px 30px 0;
		}
		
		/* iPad Portrait & Landscape */
		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px)  { 
			padding: 50px 20px 30px 0;
		}
	}

	h2 {
		text-align: center;
		margin-bottom: 40px;
		@media #{$xsmall} {
			margin-left: 20px;
		}
	}

	/* clearfix */
	.grid:after {
		content: '';
		display: block;
		clear: both;
	}

	.grid {
		/* 
		* Grid Items 
		*/
		.grid-sizer,
		.grid-item {
			width: 33%;
			@media #{$xsmall} {
				width: 50%;	
			}
			/* iPad Portrait */ 
			@media only screen 
			and (min-device-width : 768px) 
			and (max-device-width : 1024px) 
			and (orientation : portrait) { 
				width: 50%;
			}
		}

		.grid-item {
			vertical-align: top;
			text-align: center; 
			margin-bottom: 20px;

			img {
				display: block;
				margin: 0 auto 10px;
				text-align: center;
				width: 100%;
				height: 100%;
			}

			a:link, a:visited {
				display: block;
				text-decoration: none;
				padding: 30px;
				margin-left: 20px;

				@media #{$xsmall} {
					padding: 20px;
					background-color: #201e23;
				}

				/* iPad Portrait & Landscape */
				@media only screen 
				and (min-device-width : 768px) 
				and (max-device-width : 1024px)  { 
					background-color: #201e23;
					font-size: 18px;
				}
			}

			a:hover {
				background-color: #201e23;
			}
		}
	}
}