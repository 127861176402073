.section-social {
	background: $darkGrey;
	margin: 0;
	padding: 0;
	width: 100%;
	text-align: center;

	.social-wrapper {
		padding: 40px 30px;
	}

	.social-links {
		margin: 10px 0 0; 
		padding: 0;
		li {
			margin: 0;
			padding: 0;
			display: inline-block;
			list-style: none;
			color: white;

			&:first-child {
				margin: 0 10px 0 -10px; 
			}

			a {
				text-indent: -999em;
			}
		}
	}

	h3 {
		position: relative;
		padding-bottom: 40px;
	}

	h3:after {
		content: '';
		background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAAYCAMAAABtCkWqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABblBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAACDGmI8AAAAeHRSTlMACrZBP7is+mZk+a5L8o8DjU0s3g8Ntd8tFcPUIyHEFgef6z48oXX9Y2F384yKT+C0suEvxdMg0sYXouo76aMIePhgXv55AVACiPRRMAyw4xgfHtDIpDo56KZ6XVz3fFKHhlQxrwszGcnPHMoap+dlqAl9f/X2VjT3HZ+5AAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAUhJREFUOMuVkPkzQlEYho/skYTIkrKGbGXJlsR1kez7vmeJ7Dx/vmuaaZJund6fvvN+85yZ7xF5hnyRWwoK80QRxSU5QaVGykS5CSrM0oy5EkwWIaqqwVAjCVlroc72O9U3QGOTFGRvhGZHfHa2QGubBNTeCh3OxLPThbErK9RtxNWTXFjc0JvZibkP3P1/O9sADFozefCAdyi1dQzDiF0XGvXB2Pj/3jkBk+060JQfpgNpVzMugrNpN3NBlHlV58cFzUnf4r96cQlCy/onh73gWUkpV1ZhLZxJ7/oY+Db+VJtbsL0uMiawA/7dpGJvHw4CIkvUQ4XgUeJ5fIJyqmaDtJyF4DzuRL1QCF1KMFqu1uD618lNmeYhIgcJcXsH95vi4R6ij7KQ5uQJYs8xeHmVh7SD3hRAeZfxkJzLDz6/cmS0RKLfursfAYtLvgqBY5QAAAAASUVORK5CYII=');
		background-repeat: no-repeat;
		background-size: 43px 19px;
		background-position: 0 0;
		position: absolute;
		display: block;
		left: 50%;
		top: 45px;
		width: 53px;
		height: 24px;
		transform: translateX(-50%);
	}
}