$base-fontsize: 12;

@function em($pixels, $context: $base-fontsize) {
  @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $base-fontsize) {
  @return #{$pixels/$context}rem;
}

/* Clearfix */
%clear {
	content: ' ';
	display: table;
	visibility: hidden;
	height: 0;
}
.clear {
	*zoom: 1;
	&:before {
		@extend %clear;
	}
	&:after {
		@extend %clear;
		clear: both;
	}
}