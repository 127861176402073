/* Set $img as background-image & give the image dimensions to the element */
@mixin background-image-fit($img){
    background-image: url($img);
    width: image-width($img);
    height: image-height($img);
}

/* Create prefixed keyframes */
@mixin keyframe ($animation_name) {
    @-webkit-keyframes $animation_name {
        @content;
    }

    @-moz-keyframes $animation_name {
        @content;
    }

    @-o-keyframes $animation_name {
        @content;
    }

    @keyframes $animation_name {
        @content;
    }
}

/* Create prefixed animation */
@mixin animation ($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards;

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards;
}
