/* ------ venobox.css --------*/
.vbox-overlay *, .vbox-overlay *:before, .vbox-overlay *:after{
    -webkit-backface-visibility: hidden;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
/* ------- overlay: change here background color and opacity ----- */
.vbox-overlay {
    background: #fdfdfd;
    background: rgba(0,0,0,0.85); 
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

@media (max-width: 460px) {
    .vbox-iframe.vbox-overlay {
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        position: absolute;
    }
}

/* ----- preloader - choose between CIRCLE, IOS, DOTS, QUADS ----- */

/* circle preloader */
.vbox-preloader{
    position:fixed; 
    width:32px; 
    height:32px;
    left:50%; 
    top:50%; 
    margin-left:-16px; 
    margin-top:-16px;
    background-image: url(/images/venobox/preload-circle.png);
    text-indent: -100px;
    overflow: hidden;
    -webkit-animation: playload 1.4s steps(18) infinite;
       -moz-animation: playload 1.4s steps(18) infinite;
        -ms-animation: playload 1.4s steps(18) infinite;
         -o-animation: playload 1.4s steps(18) infinite;
            animation: playload 1.4s steps(18) infinite;
}
@-webkit-keyframes playload {
   from { background-position:    0px; }
     to { background-position: -576px; }
}
@-moz-keyframes playload {
   from { background-position:    0px; }
     to { background-position: -576px; }
}
@-ms-keyframes playload {
   from { background-position:    0px; }
     to { background-position: -576px; }
}
@-o-keyframes playload {
   from { background-position:    0px; }
     to { background-position: -576px; }
}
@keyframes playload {
   from { background-position:    0px; }
     to { background-position: -576px; }
}

/* ----- navigation ----- */

.vbox-close {
    cursor: pointer;
    width: 46px;
    height: 40px;
    padding: 10px 20px 10px 0;
    display: block;
    color: #fff;
    text-indent: -100px;
    overflow: hidden;
    background-color: transparent;
    // background-image: url(/images/venobox/close.gif);
    background-repeat: no-repeat;
    // background-position: 10px center;
    background-position: 0 0;
    background-size: 30px 30px;
    z-index: 10;
}

.vbox-youtube {
    .vbox-close {
        position: fixed;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);    
        background-image: url('/images/close-white.svg');
    }
}

.vbox-iframe {
    .vbox-close {
        position: absolute;
        top: 0;
        left: auto;
        background-image: url('/images/close-blk.svg');
        @media (min-width: 1200px) {        
            right: 130px !important; 
        }
    }
}

.vbox-next, .vbox-prev{
    box-sizing: content-box;
    cursor: pointer;
    position: fixed;
    top: 50%;
    color: #fff;
    width: 30px;
    height: 170px;
    margin-top: -85px;
    text-indent: -100px;
    border: solid transparent; /* Using border instead of padding to keep bg image in place */
    overflow: hidden;
}
.vbox-prev{
    left: 0;
    border-width: 0 30px 0 10px;
    background: url(/images/venobox/prev.gif) center center no-repeat;
}
.vbox-next{
    right: 0;
    border-width: 0 10px 0 30px;
    background: url(/images/venobox/next.gif) center center no-repeat;
}

.vbox-title{
    width: 100%;
    height: 40px;
    float: left;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
    color: #fff;
    padding: 6px 40px;
    overflow: hidden;
    background: #161617;
    position: fixed;
    top: -1px;
    left: 0;

    display: none !important;
}
.vbox-num{
    cursor: pointer;
    position: fixed;
    top: -1px;
    left: 0;
    height: 40px;
    display: block;
    color: #fff;
    overflow: hidden;
    line-height: 28px;
    font-size: 12px;
    padding: 6px 10px;
    background: #161617;
    display: none;
}
/* ------- inline window ------ */
.vbox-inline{
    width: 420px;
    height: 315px;
    padding: 10px;
    background: #fff;
    text-align: left;
    margin: 0 auto;
    overflow: auto;
}

/* ------- Video & iFrames window ------ */
.venoframe{
    border: none;
    width: 960px;
    height: 720px;
}
@media (max-width: 992px) {
    .venoframe{
        width: 640px;
        height: 480px;
    }
}
@media (max-width: 767px) {
    .venoframe{
        width: 420px;
        height: 315px;
    }
}
@media (max-width: 460px) {
    .vbox-iframe {
        .vbox-container {
            padding: 0 !important;
        }
        .vbox-inline{
            width: 100%;
        }
        .venoframe{
            width: 100%;
            height: 100vh;
        }
    }
}
@media (max-width: 320px) {
    .venoframe{
        width: 290px;
        height: 180px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
    .vbox-iframe {
        .venoframe {
            height: 100vh;
            width: 100%;
        }
    }
}

/* ------- PLease do NOT edit this! (or do it at your own risk) ------ */
.vbox-open{
    overflow: hidden;
}
.vbox-container{
    position: relative;
    background: #000;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}
.vbox-content{
    text-align: center;
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.vbox-container img{
    max-width: 100%;
    height: auto;
}