.ctaBtn {
	position: relative;
	padding-right: 20px;
	
	&:after {
		content: '';
		background-image: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAATCAMAAACawLzhAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAclBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAABe5mfWAAAAJXRSTlMAHrVRFdEqMNAPWAOLg7hTEi0RWp1ya6M9xwoc1AlAoG+fxgiowsK47wAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABUSURBVAjXLc1JDoAgEERRRFEU5xlRxOH+ZzSxqjf/JbVoEQmejIlEpVSmc6qQhiorwtQN1eqO6lRPDZIYJ3Re0NVuf92Oz4f1GM6Ahgv19wO8Dv0Av58DO5w2d9wAAAAASUVORK5CYII=');
		background-repeat: no-repeat;
		position: absolute;
		top: 3px;
		right: 0;
		width: 8px;
		height: 19px;
		display: block;
	}
}

.gainomax-logo {
	display: block;
	background-image: url('../images/logo-gainomax.png');
	background-repeat: no-repeat;
}

.position-fixed {
	position: fixed;
	overflow: hidden;
}