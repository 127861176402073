h1, h2, h3, p, a {
	color: $white;
	font-family: $baseFontFamily;
}

h2 {
	font-size: 35px;
	line-height: 38px;
	margin-bottom: 20px;

	@media #{$xsmall} {
		font-size: 28px;
		line-height: 32px;
	}
}

h3 {
	font-size: 25px;
	line-height: 28px;
	
}

a:link, a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

p {
	margin-bottom: 20px;
	font-size: 22px;
	line-height: 25px;
}

ul {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
		margin: 0; 
		padding: 0;
		color: $white;
	}
}