/* Font faces */
$baseFontFamily: 'HelveticaNeueLTStd-LtCn', Helvetica, Arial;
$boldFontFamily: 'HelveticaNeueLTStd-XBlkCn', Helvetica, Arial;

// Colors
$white : #ffffff;
$black : #111014;
$darkGrey : #19171c;
$blue : #62b5e5;

/* Break Points */
$xsmall: "screen and (max-width: 640px)";
$small: "screen and (max-width: 800px)";
$medium: "screen and (max-width: 1100px)";

/* Break Points */
// $small: "screen and (min-width: 768px)";
$xsmall-min: "screen and (min-width: 641px)";
$large: "screen and (min-width: 1025px)";
$xlarge: "screen and (min-width: 1400px)";
$xxlarge: "screen and (min-width: 1700px)";