.sub-page-wrapper {
	padding: 50px; 

	h1 {
		color: $black;
		text-align: center;
		font-size: 30px;
		margin-bottom: 30px;
	}
	p {
		color: $black;
		margin-bottom: 10px;
	}
}