.section-banana-facts {
	background-color: $darkGrey;
	margin: 0;
	padding: 0;
	width: 100%;
	text-align: center;

	h2 {
		margin: 0;
	}

	.banana-facts-wrapper {
		padding: 50px 30px;	
		@media #{$xsmall} {
			max-width: 200px;
			margin: 0 auto;
		}
	}
}

.section-banana-facts-grid {
	width: 100%;

	.col {
		height: 330px; 
		text-align: center;
		background-size: cover;

		&.blue {
			background-image: url('../images/bg-facts-blue.jpg');
			background-repeat: no-repeat;
		}
		&.purple {
			background-image: url('../images/bg-facts-purple.jpg');
			background-repeat: no-repeat;
		}
		&.orange {
			background-image: url('../images/bg-facts-orange.jpg');
			background-repeat: no-repeat;
		}
		&.pink {
			background-image: url('../images/bg-facts-pink.jpg');
			background-repeat: no-repeat;
		}
		&.yellow {
			background-image: url('../images/bg-facts-yellow.jpg');
			background-repeat: no-repeat;
		}
		&.green {
			background-image: url('../images/bg-facts-green.jpg');
			background-repeat: no-repeat;
		}

		p {
			padding: 0 30px;
			max-width: 400px;
			text-align: center;
			margin: 0 auto;
		}

		.icon {
			margin: 50px auto 50px;
			background-repeat: no-repeat;
		}

		.icon-01 {
			background-image: url('../images/banana-facts/01.png');
			background-size: 73px 64px;
			// width: 146px;
			// height: 127px;
			width: 73px;
			height: 64px;
		}

		.icon-02 {
			background-image: url('../images/banana-facts/02.png');	
			background-size: 127px 60px;
			// width: 253px;
			// height: 119px;
			width: 127px;
			height: 60px;
		}

		.icon-03 {
			background-image: url('../images/banana-facts/03.png');
			background-size: 65px 58px;
			// width: 131px;
			// height: 116px;
			width: 65px;
			height: 58px;
		}

		.icon-04 {
			background-image: url('../images/banana-facts/04.png');
			background-size: 51px 60px;
			// width: 102px;
			// height: 119px;
			width: 51px;
			height: 60px;
		}

		.icon-04b {
			background-image: url('../images/banana-facts/04-b.png');
			background-size: 51px 60px;
			// width: 102px;
			// height: 119px;
			width: 51px;
			height: 60px;
		}

		.icon-05 {
			background-image: url('../images/banana-facts/05.png');
			background-size: 97px 60px;
			// width: 194px;
			// height: 120px;
			width: 97px;
			height: 60px;
		}

		.icon-06 {
			background-image: url('../images/banana-facts/06.png');
			background-size: 82px 61px;
			// width: 164px;
			// height: 122px;
			width: 82px;
			height: 61px;
		}

	}	
}

.fi { 
	.section-banana-facts-grid .col {
		height: 360px; 
		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
			height: 420px; 
		}
	}
}