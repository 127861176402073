.section-testimonials {
	width: 100%;
	display: block;
	background: $black;
	margin: 0;
	padding: 0;

	.carousel-slide {
		.slideTextWrap {
			padding-left: 10%; 

			@media #{$xsmall} {
				padding: 0 5%;
			}

			p {
				max-width: 510px;
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;

				@media #{$xsmall} {
					font-size: 20px;
					line-height: 22px;
				}
			}
			.byline {
				color: $white;
				font-size: 22px;
				margin-bottom: 20px;

				@media #{$xsmall} {
					font-size: 18px;
					line-height: 20px;
				}
			}
			.ctaBtn {
				font-size: 20px;
				color: $blue;
				
				@media #{$xsmall} {
					font-size: 18px;
					line-height: 20px;
				}
			}
		}
	}

}