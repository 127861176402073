.footer {
	margin: 0;
	padding: 0;
	display: block;
	background: $black;
	width: 100%;

	.footer-wrapper {
		padding: 30px;

		@media #{$xsmall} {
			padding: 20px;
		}
	}

	.gainomax-logo {
		width: 150px;
		height: 43px;
		background-size: 150px 43px;
		float: left;

		@media #{$xsmall} {
			background-size: 120px 34px;
		}
	}

	.right {
		float: right;
		text-align: right;
	}

	.copyright {
		font-size: 13px;
	}

	.social-links {
		margin: 0 0 20px; 
		padding: 0;
		li {
			margin: 0;
			padding: 0;
			display: inline-block;
			list-style: none;
			color: white;

			&:first-child {
				margin-right: 10px;
			}

			a {
				text-indent: -999em;
				text-align: left;
			}
		}
	}	
}